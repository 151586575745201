import React from 'react';
import Image from '../Atoms/Image/Image';
import OrderRowItem from '../Order/Models/OrderRowItem.interface';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import { styled } from '../stitches.config';

type PropType = {
  orderRows: any;
};

function OrderRowList({ orderRows }: PropType) {
  const { 'productItem/size': sizeLabel } = useTranslationData();

  return orderRows.map((product: OrderRowItem) => (
    <React.Fragment key={product.name}>
      <Item>
        {product.image && (
          <ImageWrapper>
            <StyledImage src={product.image?.src} alt={product.name} />
          </ImageWrapper>
        )}
        <Info>
          <Details>
            <DetailsTop>
              <DetailTextBold>{`${product.quantityString} x `}</DetailTextBold>
              <Title>{product.name}</Title>
              {product.size && (
                <Size>{`${' '}-${sizeLabel}: ${product.size}`}</Size>
              )}
            </DetailsTop>
            <PriceText>{product.totalPrice}</PriceText>
          </Details>
        </Info>
      </Item>
    </React.Fragment>
  ));
}

const Item = styled('div', {
  display: 'flex',
  alignItems: 'center',
  pt: 4,
  '&:not(:last-child)': {
    pb: 4,
    borderBottom: '1px solid $primary',
  },
});

const Size = styled('span', { fs: 6 });

const StyledImage = styled(Image, {
  objectFit: 'contain',
  h: '100%',
});

const ImageWrapper = styled('div', {
  maxW: 8,
  w: '100%',
  h: 11.5,
});

const Info = styled('div', {
  display: 'flex',
  w: '100%',
  justifyContent: 'start',
  ml: 4,
  '@mediaMinMedium': {
    justifyContent: 'space-between',
  },
});

const Title = styled('span', {
  fs: 7,
  fontWeight: '$fw700',
  mb: 2,
});

const Details = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const DetailsTop = styled('div', {
  mb: 2.5,
});

const DetailTextStyleBase = {
  fs: 6,
  mb: 1,
  '&:last-of-type': {
    mb: 2,
  },
};

const PriceText = styled('span', {
  ...DetailTextStyleBase,
  fontWeight: '$fw700',
  lineHeight: '16px',
});

const DetailTextBold = styled('span', {
  ...DetailTextStyleBase,
  fontWeight: '$fw700',
  fs: 7,
  lineHeight: '18px',
});

export default OrderRowList;
