import OrderConfirmationPageModel from '../../OrderConfirmation/Models/OrderConfirmationPageModel.interface';
import HeroModule from '../../Organisms/Hero/HeroModule';
import OrderInformation from '../../Organisms/OrderHistory/OrderHistory';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { styled } from '../../stitches.config';
import { GetCart } from '../CartPage/Cart';

function OrderConfirmationPage() {
  const {
    order,
    pageHeading,
    subHeading,
    teaserHeader,
    teaserImage,
    teaserLinks,
    teaserText,
    teaserHeaderSize,
    teaserButtonType,
  } = useCurrentPage<OrderConfirmationPageModel>();

  GetCart();

  return (
    <main>
      {order ? (
        <>
          <PageWrapper>
            <OrderInformation
              heading={pageHeading}
              subHeading={subHeading}
              order={order}
              orderConfirmation
            />
          </PageWrapper>
          {teaserImage && (
            <HeroModule
              header={teaserHeader}
              summary={teaserText}
              links={teaserLinks}
              image={teaserImage}
              headerSize={teaserHeaderSize}
              buttonType={teaserButtonType}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </main>
  );
}

export default OrderConfirmationPage;

const PageWrapper = styled('div', {
  p: 4,
  '@mediaMinLarge': {
    maxW: '$contentMaxWidth',
    mx: 'auto',
    px: 8,
    py: 16,
  },
});
