import { useEffect, useState } from 'react';
import Divider from '../../Atoms/Divider/Divider';
import { GetMyInformation } from '../../CustomerInformation/CustomerInformationApi';
import KexLink from '../../Kex/KexLink';
import CustomerInformationModel from '../../MyPagesPage/Models/CustomerInformationModel.interface';
import OrderDetailsModel from '../../Order/Models/OrderDetailsModel.interface';
import OrderRowList from '../../OrderComponent/OrderRowList';
import { CheckoutSectionTitle } from '../../Pages/Checkout/CheckoutProductListing';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import { styled } from '../../stitches.config';

type PropType = {
  heading: string;
  subHeading: string;
  onClick?: () => void;
  order: OrderDetailsModel;
  orderConfirmation?: boolean;
};

function OrderInformation({
  heading,
  subHeading,
  onClick,
  order,
  orderConfirmation,
}: PropType) {
  const { languageRoute } = useAppSettingsData();
  const {
    'common/backLink': backLinkLabel,
    'common/total': totalLabel,
    'checkoutPage/vatTotal': vatLabel,
    'orderConfirmationPage/orderLines': orderLinesLabel,
    'orderdetail.deliveryaddress': deliveryAddressLabel,
    'common/freightInformationLabel': freightInformationLabel,
    'checkoutPage/subtotal': subTotalLabel,
  } = useTranslationData();

  const { channelId } = useCurrentPage<PageModelBase>();

  const { customerId } = useUserStateData();
  const url = `/api/${languageRoute}/customer/GetMyInformation?customerId=${customerId}`;
  const [customerInformation, setCustomerInformation] =
    useState<CustomerInformationModel>();

  useEffect(() => {
    customerId &&
      !orderConfirmation &&
      GetMyInformation(channelId, url).then(
        (data: any) => data && setCustomerInformation(data)
      );
  }, [channelId]);

  return (
    <>
      <OrderHistoryComponentWrapper>
        {!orderConfirmation && (
          <BackLinkWrapper>
            <BackLink onClick={onClick}>{backLinkLabel}</BackLink>
          </BackLinkWrapper>
        )}
        <LeftSection>
          <CheckoutSectionTitle>{heading}</CheckoutSectionTitle>
          <IntroText
            dangerouslySetInnerHTML={{
              __html: subHeading,
            }}
          />
          <OrderItemsTable>
            <CheckoutSectionTitle>{orderLinesLabel}</CheckoutSectionTitle>
            <OrderRowList orderRows={order.orderRows} />
          </OrderItemsTable>
        </LeftSection>

        <RightSection>
          <DeliverySection>
            <DeliveryAddress>{deliveryAddressLabel}</DeliveryAddress>
            {customerInformation ? (
              <DeliveryAddressContainer>
                <Item>{customerInformation?.companyName}</Item>
                <Item>{customerInformation?.deliveryAddress?.street}</Item>
                <Item>
                  {customerInformation?.deliveryAddress?.postalCode},{' '}
                  {customerInformation?.deliveryAddress?.city}
                </Item>
              </DeliveryAddressContainer>
            ) : (
              <DeliveryAddressContainer>
                <Item>{order?.companyName}</Item>
                <Item>
                  {order.customerInfo?.firstName} {order.customerInfo?.lastName}
                </Item>
                <Item>{order.customerInfo?.address1}</Item>
                <Item>
                  {order.customerInfo?.zip} {order.customerInfo?.city}{' '}
                </Item>
              </DeliveryAddressContainer>
            )}
          </DeliverySection>
          <BottomSection>
            <ItemFlex>
              <span>{subTotalLabel}</span>
              <span>{order.totalPriceForItems}</span>
            </ItemFlex>
            <ItemFlex>
              <span>{vatLabel}</span>
              <span>{order.orderTotalVat}</span>
            </ItemFlex>
            <StyledDivider />
            <TotalSumArea>
              <span>{totalLabel}</span>
              <span>{order.orderGrandTotal}</span>
            </TotalSumArea>
            <TotalSumArea>
              <span>{freightInformationLabel}</span>
            </TotalSumArea>
          </BottomSection>
        </RightSection>
      </OrderHistoryComponentWrapper>
    </>
  );
}

const DeliveryAddressContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const OrderHistoryComponentWrapper = styled('div', {
  display: 'grid',
  g: 4,
  gridTemplateColumns: '1fr',
  wh: '100%',
  justifyContent: 'center',
  mx: 'auto',
  '@mediaMinLarge': {
    gridTemplateColumns: '6.5fr 3.5fr',
    g: 8,
    mx: 'auto',
  },
  maxW: '$contentMaxWidth',
});

const IntroText = styled('div', {
  fs: 6,
  lineHeight: '20px',
  mb: 4,
  '@mediaMinLarge': {
    fs: 8,
  },
});

const ItemFlex = styled('div', {
  fs: 6,
  display: 'flex',
  variants: {
    margin: {
      true: {
        mb: 4,
      },
    },
  },
  justifyContent: 'space-between',
  '@mediaMinLarge': {
    fs: 8,
  },
});

const DeliveryAddress = styled('div', {
  mb: 2,
  fs: 6,
  fontWeight: '$fw700',
  lineHeight: '20px',
  '@mediaMinLarge': {
    fs: 8,
  },
});

const BackLinkWrapper = styled('div', {
  display: 'flex',
});

const BackLink = styled(KexLink, {
  fs: 8,
  cursor: 'pointer',
  textDecoration: 'underline',
  pl: 4,
});

const LeftSection = styled('div', {
  backgroundColor: '$white',
  p: 4,
  '@mediaMinLarge': {
    p: 8,
  },
});

const Item = styled('span', {
  fs: 6,
  lineHeight: '20px',
  '@mediaMinLarge': {
    fs: 8,
  },
});

const RightSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  g: 4,
  '@mediaMinLarge': {
    g: 8,
  },
});

const DeliverySection = styled('div', {
  backgroundColor: '$white',
  p: 4,
  '@mediaMinLarge': {
    p: 8,
  },
});

const TotalSumArea = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  fs: 6,
  fontWeight: '$fw700',
  lineHeight: '28px',
  '@mediaMinLarge': {
    fs: 8,
  },
});

const BottomSection = styled('div', {
  backgroundColor: '$white',
  p: 4,
  '@mediaMinLarge': {
    p: 8,
  },
});

const OrderItemsTable = styled('div', {
  display: 'table',
  w: '100%',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
});

const StyledDivider = styled(Divider, {
  width: '100%',
  backgroundColor: '$borderHoverPrimary',
  my: 4,
});

export default OrderInformation;
